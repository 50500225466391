@import './styles/wieldy.less';

.ant-menu-title-content span {
  font-weight: 500;
}

.gx-card-widget .ant-card-head {
  padding-top: 18px;
  padding-bottom: 14px;
}

.gx-card-widget .ant-card-head .ant-card-head-title {
  font-weight: 500;
}

.ant-form .ant-card {
  border-radius: 10px;
  margin-bottom: 18px;
}

.ant-form .ant-card-body {
  padding: 18px;
}

.ant-card h4 {
  color: #111111;
  margin-bottom: 0.7em;
}

.ant-card .ant-form-item-label > label {
  color: #555555;
}

.ant-card .ant-form-item-label {
  padding-bottom: 4px;
}

.ant-form-item-label > label {
  font-weight: 500;
  font-size: 13px;
}

.ant-descriptions-item-label {
  min-width: 130px;
  font-weight: bold;
}

.ant-descriptions-item-colon::after {
  float: right;
}

div.site-content {
  width: 100%;
  background: rgb(217, 217, 217);
  background: linear-gradient(90deg, rgba(200, 200, 200, 1) 0%, rgba(245, 245, 245, 1) 2.5%);
}

div.container {
  margin: 24px;
  padding: 20px;
  /* max-width: 1280px; */
}

div.center {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-items: center;
}

div.center-screen {
  right: calc(50vw - 300px);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.align-right {
  text-align: right;
  flex-flow: column;
  align-items: flex-end;
  justify-content: right;
}
.cursor-pointer:hover {
  cursor: pointer;
}
div.container > form.ant-form {
  max-width: 700px;
  background-color: white;
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-right: 40px;
  padding-left: 10px;
  box-shadow: 5px 8px 12px 1px rgba(219, 219, 219, 0.84);
}

h3 {
  border-bottom: 1px solid lightgray;
  padding-right: 100px;
  padding-bottom: 10px;
  margin-bottom: 28px;
}

.bold,
.text-bold {
  font-weight: 600 !important;
}

button.ant-btn-sm {
  line-height: 18px;
  padding-left: 10px;
  padding-right: 10px;
}

a {
  font-weight: 500;
}

.bg-white {
  background-color: white !important;
}

.nowrap {
  white-space: nowrap;
}

.ant-popover-content {
  min-width: 250px !important;
}
/* 
.list-sort-demo-wrapper {
  position: relative;
  border-radius: 10px;
  background: #e6e6e6;
  overflow: hidden;
  height: 385px;
}

.list-sort-demo {
  margin: 8px auto;
  max-width: 400px;
  width: 100%;
  cursor: -moz-grab !important;
  position: relative;
  height: 100px;
}
.list-sort-demo > div {
  overflow: hidden;
}

.list-sort-demo-list {
  background: #fff;
  border-radius: 8px;
  margin: 8px auto;
  padding: 10px;
  height: 70px;
  transition: box-shadow 0.2s, transform 0.2s;
}

.list-sort-demo-list.list-drag-selected {
  box-shadow: 0 8px 20px #e6e6e6;
  transform: scale(1.05) !important;
}

.list-sort-demo-icon {
  width: 20%;
  display: inline-block;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  vertical-align: top;
}
.list-sort-demo-text {
  width: 80%;
  display: inline-block;
}

.list-sort-demo-text h1 {
  font-size: 18px;
}

.list-sort-demo-text p {
  font-size: 12px;
}

@media screen and (max-width: 320px) {
  .list-sort-demo-text h1 {
    font-size: 14px;
    line-height: 28px;
  }
} */
