// Box Shadow
.box-shadow(@shadow...) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}

// Single side border-radius
.border-radius(@radius...) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

// Single side box-sizing
.box-sizing(@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing: @type;
  box-sizing: @type;
}

// Opacity
.opacity(@opacity: 0.5) {
  -webkit-opacity: @opacity;
  -moz-opacity: @opacity;
  opacity: @opacity;
}

// Transition
.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

// Rotate
.rotate(@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  -o-transform: rotate(@deg);
  transform: rotate(@deg);
}

// Scale
.scale(@factor) {
  -webkit-transform: scale(@factor);
  -moz-transform: scale(@factor);
  -ms-transform: scale(@factor);
  -o-transform: scale(@factor);
  transform: scale(@factor);
}

// Translate
.translate(@factor, @factor) {
  -webkit-transform: translate(@factor, @factor);
  -ms-transform: translate(@factor, @factor);
  transform: translate(@factor, @factor);
}

// --------------------------------------------------
// Flexbox LESS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
.flex-display(@display: flex, @direction: row, @wrap: wrap) {
  display: ~"-webkit-@{display}";
  display: ~"-ms-@{display}box"; // IE10 uses -ms-flexbox
  display: ~"-ms-@{display}"; // IE11
  display: @display;

  -webkit-flex-direction: @direction;
  -ms-flex-direction: @direction;
  flex-direction: @direction;
  -webkit-flex-wrap: @wrap;
  -ms-flex-wrap: @wrap;
  flex-wrap: @wrap;
}

// Flex Direction and Wrap
.flex-flow(@flow) {
  -webkit-flex-flow: @flow;
  -ms-flex-flow: @flow;
  flex-flow: @flow;
}

// Display Order
.flex-order(@order: 0) {
  -webkit-order: @order;
  -ms-order: @order;
  order: @order;
}

// Flex  - applies to: flex items
.flex(@flex-grow: 0, @flex-shrink: 1, @flex-basis: auto) {
  -webkit-flex: @flex-grow @flex-shrink @flex-basis;
  -ms-flex: @flex-grow @flex-shrink @flex-basis;
  flex: @flex-grow @flex-shrink @flex-basis;
}

.flex-only(@flex-only) {
  -webkit-flex: @flex-only;
  -ms-flex: @flex-only;
  flex: @flex-only;
}

// Axis Alignment
// flex-start | flex-end | center | space-between | space-around
.justify-content(@justify: flex-start) {
  -webkit-justify-content: @justify;
  -ms-justify-content: @justify;
  justify-content: @justify;
}

// Packing Flex Lines
// flex-start | flex-end | center | space-between | space-around | stretch
.align-content(@align: stretch) {
  -webkit-align-content: @align;
  -ms-align-content: @align;
  align-content: @align;
}

// Cross-axis Alignment
// flex-start | flex-end | center | baseline | stretch
.align-items(@align: stretch) {
  -webkit-align-items: @align;
  -ms-align-items: @align;
  align-items: @align;
}

// Cross-axis Alignment
// auto | flex-start | flex-end | center | baseline | stretch
.align-self(@align: auto) {
  -webkit-align-self: @align;
  -ms-align-self: @align;
  align-self: @align;
}

//Vertical Gradient
.vertical-gradient (@vstartColor: @orange-6, @vendColor: @red-6) {
  background-color: @vstartColor;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(@vstartColor),
    to(@vendColor)
  );
  background: -webkit-linear-gradient(top, @vstartColor, @vendColor);
  background: -moz-linear-gradient(top, @vstartColor, @vendColor);
  background: -ms-linear-gradient(top, @vstartColor, @vendColor);
  background: -o-linear-gradient(top, @vstartColor, @vendColor);
}

//Horizontal Gradient
.horizontal-gradient (@hstartColor: @orange-6, @hendColor: @red-6) {
  background-color: @hstartColor;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(@hstartColor),
    to(@hendColor)
  );
  background: -webkit-linear-gradient(left, @hstartColor, @hendColor);
  background: -moz-linear-gradient(left, @hstartColor, @hendColor);
  background: -ms-linear-gradient(left, @hstartColor, @hendColor);
  background: -o-linear-gradient(left, @hstartColor, @hendColor);
}

//Corner Left Gradient
.corner-left-gradient (@hstartColor: @cyan-6, @hendColor: @blue-8) {
  background: -moz-linear-gradient(125deg, @hstartColor 0%, @hendColor 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0%, @hstartColor),
    color-stop(100%, @hendColor)
  );
  background: -webkit-linear-gradient(125deg, @hstartColor 0%, @hendColor 100%);
  background: -o-linear-gradient(125deg, @hstartColor 0%, @hendColor 100%);
  background: -ms-linear-gradient(125deg, @hstartColor 0%, @hendColor 100%);
  background: linear-gradient(325deg, @hstartColor 0%, @hendColor 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@hstartColor', endColorstr='@hendColor', GradientType=0);
}
